<div
  class="active-filter-tags"
  [ngClass]="{
    'active-filter-tags--empty': !activeFilters().length
  }">
  @for (tag of activeFilters(); track tag) {
    <shared-active-filter-tag
      [tag]="tag"
      (removeFilter)="onRemoveFilter($event)"></shared-active-filter-tag>
  }
</div>

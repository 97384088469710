export const getFilterOptionsForColumn = <T>(
  dataList: T[],
  col: string,
  delimiter?: string,
) => {
  let columnSet = dataList.map((el: T) => (el as any)[col]).filter((v) => v);

  if (delimiter) {
    columnSet = columnSet
      .flatMap((value: any) => value.toString().split(delimiter))
      .map((el) => el.trim());
  }

  const notEmptyColumnSet = columnSet.filter((value) => value !== '');
  const uniqueData = [...new Set(notEmptyColumnSet)];

  return uniqueData.map((el) => ({
    label: el.toString(),
    value: el,
  }));
};

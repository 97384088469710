/* eslint-disable no-param-reassign */
import { TableLazyLoadEvent } from 'primeng/table';
import { DateTime } from 'luxon';

import { Filter, FilterMode, FilteringConfig } from '../models';
import { COLUMN_DELIMITER } from '../constants';
import { DEFAULT_DATE_FORMAT } from './grid-sorting.helpers';

export const createFilteringConfig = (
  event: TableLazyLoadEvent,
): FilteringConfig => {
  const { filters } = event;

  if (filters) {
    return Object.keys(filters).reduce(
      (filtered: FilteringConfig, key: string) => {
        const filter = filters[key];

        if (Array.isArray(filter)) {
          const nonNullValues = filter.filter((f) => f.value !== null);

          if (nonNullValues.length > 0) {
            const firstNonNullValue = nonNullValues[0];
            filtered[key] = {
              matchMode: firstNonNullValue.matchMode as string,
              operator: firstNonNullValue.operator as string,
              value: Array.isArray(firstNonNullValue.value)
                ? firstNonNullValue.value
                : [firstNonNullValue.value],
            } as Filter;
          }
        } else if (filter && filter.value !== null) {
          filtered[key] = {
            value: filter.value,
            matchMode: filter.matchMode,
            operator: filter.operator,
          } as Filter;
        }

        return filtered;
      },
      {},
    );
  }

  return {};
};

const applyInFilter = (itemValue: any, filter: Filter): boolean => {
  const filterValues = filter.value.map((v) => v.value);

  if (!itemValue) {
    itemValue = '';
  }

  const splitValues: string[] = itemValue.split(COLUMN_DELIMITER);

  return (
    filterValues.length === 0 ||
    splitValues.some((splitValue) => filterValues.includes(splitValue.trim()))
  );
};

const applySearchFilter = (itemValue: any, filter: Filter): boolean => {
  const filterValue = filter.value[0];

  if (!itemValue) {
    itemValue = '';
  }

  return (
    filter.value.length === 0 ||
    itemValue.toLowerCase().startsWith(filterValue.value.toLowerCase())
  );
};

export const applySingleDateFilter = (
  itemValue: any,
  filterValue: any,
): boolean => {
  const rowDate = DateTime.fromFormat(itemValue, DEFAULT_DATE_FORMAT);
  const filterDate = DateTime.fromFormat(
    filterValue[0].value,
    DEFAULT_DATE_FORMAT,
  );

  return filterDate.equals(rowDate);
};

export const applyDateRangeFilter = (
  itemValue: any,
  filterValue: any,
): boolean => {
  const rowDate = DateTime.fromFormat(itemValue, DEFAULT_DATE_FORMAT);
  const startFilterDate = DateTime.fromFormat(
    filterValue[0].value,
    DEFAULT_DATE_FORMAT,
  );
  const endFilterDate = DateTime.fromFormat(
    filterValue[1].value,
    DEFAULT_DATE_FORMAT,
  );

  return startFilterDate <= rowDate && rowDate <= endFilterDate;
};

const applyDateFilter = (itemValue: any, filter: Filter): boolean => {
  const filterValue = filter.value;

  if (!filterValue || filterValue.length === 1)
    return applySingleDateFilter(itemValue, filterValue);
  if (!filterValue || filterValue.length !== 2) return true;
  if (filterValue.some((v) => v === null)) return true;

  return applyDateRangeFilter(itemValue, filterValue);
};

const filterHandlers = {
  [FilterMode.In]: applyInFilter,
  [FilterMode.DateAfter]: applyDateFilter,
  [FilterMode.DateBefore]: applyDateFilter,
  [FilterMode.StartsWidth]: applySearchFilter,
};

const applyFilter = (itemValue: any, filter: Filter): boolean => {
  const filterHandler = filterHandlers[filter.matchMode];

  if (filterHandler) {
    return filterHandler(itemValue, filter);
  }

  return true;
};

export const filterData = <T extends Record<string, any>>(
  data: T[],
  config: FilteringConfig,
): T[] => {
  const filteredData = data.filter((item) =>
    Object.keys(config).every((key) => applyFilter(item[key], config[key])),
  );

  return filteredData;
};

export const isAnyFilterActive = (config: FilteringConfig): boolean =>
  Object.keys(config).some((key) => {
    const filter = config[key];

    return Array.isArray(filter.value) && filter.value.length > 0;
  });

export const mapFilterConfigToValues = (
  filterConfig: FilteringConfig,
  filterName: string,
  convertFunc?: any,
) => {
  if (!filterConfig[filterName]) {
    return null;
  }

  const values = filterConfig[filterName].value;

  if (values && !values.length) {
    return null;
  }

  return values.map((value) =>
    convertFunc ? convertFunc(value.value) : value.value,
  );
};

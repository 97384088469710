import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'shared-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  private _value!: string;
  className = '';
  @Input() statusClassMap!: { [key: string]: string };

  @Input()
  set value(status: string) {
    this._value = status;
    this.className = this.getStatus(this._value);
  }

  get value(): string {
    return this._value;
  }

  getStatus(status: string): string {
    const normalizedStatus = status.toLowerCase();

    return status && this.statusClassMap[normalizedStatus]
      ? this.statusClassMap[normalizedStatus]
      : 'misty-rose';
  }
}

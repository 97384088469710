<ng-container *transloco="let t">
  <div
    cdkDropList
    class="grid-cols-dropdown"
    [cdkDropListDisabled]="dropListDisabled"
    (cdkDropListDropped)="drop($event)">
    <div class="grid-cols-dropdown__header">
      <p-triStateCheckbox
        [label]="t('all') | titlecase"
        [checkboxFalseIcon]="'pi pi-minus'"
        [(ngModel)]="selectAll"
        (onChange)="onChangeSelectAll($event)"></p-triStateCheckbox>
      <button
        pButton
        class="p-button-outlined"
        [disabled]="resetButtonDisabled"
        (click)="reset()">
          {{ t('reset') | titlecase }}
      </button>
    </div>
    @for (col of columns; track col.field) {
      <div
        class="drag-box"
        cdkDrag
        [cdkDragDisabled]="col.fixed"
        [ngClass]="{ disabled: col.fixed, active: !col.disabled }">
        <p-checkbox
          name="column"
          [disabled]="col.fixed"
          label="{{ t(col.displayName) }}"
          [value]="col.field"
          [(ngModel)]="selectedColumns"
          (ngModelChange)="columnsChanged()"></p-checkbox>
        <i class="pi pi-bars"></i>
      </div>
    }
  </div>
</ng-container>

export enum ObjectType {
  Grid = 'Grid',
  Calendar = 'Calendar',
}

export enum PageName {
  AuditList = 'AuditList',
  AuditDetails = 'AuditDetails',
  FindingList = 'FindingList',
  FindingDetails = 'FindingDetails',
  CertificateList = 'CertificateList',
  CertificateDetails = 'CertificateDetails',
  ScheduleList = 'ScheduleList',
}

export enum ObjectName {
  Audits = 'Audits',
  Findings = 'Findings',
  SubAudits = 'SubAudits',
  Sites = 'Sites',
  Documents = 'Documents',
  Certificates = 'Certificates',
  Schedules = 'Schedules',
}

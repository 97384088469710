<div class="date-filter">
  <p-calendar
    dateFormat="dd-mm-yy"
    [selectionMode]="selectionMode"
    [placeholder]="placeholder"
    [iconDisplay]="'input'"
    [showIcon]="true"
    [readonlyInput]="true"
    [(ngModel)]="date"
    (onSelect)="onSelectDate(date)"></p-calendar>
</div>

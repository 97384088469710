import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { TranslocoDirective } from '@jsverse/transloco';

import { ActionsMenuComponent } from '../../../actions-menu';

@Component({
  selector: 'shared-event-action',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ActionsMenuComponent,
    TranslocoDirective,
  ],
  templateUrl: './event-action.component.html',
  styleUrl: './event-action.component.scss',
})
export class EventActionComponent {
  @Input() displayConfirmButton = false;
  @Input() eventActions: MenuItem[] = [];

  handleConfirm(): void {}
}

export interface GridAction {
  label: string;
  iconClass: string;
  url?: string;
  actionType: GridActionType;
}

export enum GridActionType {
  Download = 'download',
  Delete = 'delete',
}

export interface GridActionEvent {
  url?: string;
  actionType: GridActionType;
}

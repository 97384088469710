import saveAs from 'file-saver';

export const downloadExcelFromByteArray = (
  inputArray: number[],
  fileName: string,
) => {
  const uint8Array = new Uint8Array(inputArray);
  const blob = new Blob([uint8Array], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, fileName);
};

export interface ColumnDefinition {
  field: string;
  displayName: string;
  type: ColumnType;
  cellType: CellType;
  disabled: boolean;
  fixed: boolean;
}

export enum ColumnType {
  Actions = 'actions',
  Checkbox = 'checkbox',
  CheckboxFilter = 'checkboxFilter',
  DateFilter = 'dateFilter',
  EventActions = 'eventActions',
  SearchCheckboxFilter = 'searchCheckboxFilter',
  TextSearchFilter = 'textSearchFilter',
}

export enum CellType {
  Actions = 'actions',
  Checkbox = 'checkbox',
  Date = 'date',
  EventActions = 'eventActions',
  Link = 'link',
  Status = 'status',
  Tag = 'tag',
  Text = 'text',
  TextWithIcon = 'textWithIcon',
}

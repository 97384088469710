import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagModule } from 'primeng/tag';
import { TranslocoDirective } from '@jsverse/transloco';

import { GridTooltipDirective } from '../../../../../directives';
import { ActiveFilterTag } from '../../../../../models';

@Component({
  selector: 'shared-active-filter-tag',
  standalone: true,
  imports: [CommonModule, TagModule, TranslocoDirective, GridTooltipDirective],
  templateUrl: './active-filter-tag.component.html',
  styleUrl: './active-filter-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveFilterTagComponent {
  @Output() removeFilter = new EventEmitter<ActiveFilterTag>();
  @Input() tag!: ActiveFilterTag;
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

type CustomBtnType = 'primary' | 'outlined';

@Component({
  selector: 'shared-custom-btn',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './custom-btn.component.html',
  styleUrl: './custom-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBtnComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() type: CustomBtnType = 'primary';
  @Input() disabled = false;

  @Output() clickBtn = new EventEmitter<void>();
}

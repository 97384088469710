<ng-container *transloco="let t">
  <div id="content-table">
    <p-table
      #dt
      styleClass="p-datatable-striped"
      columnResizeMode="expand"
      role="table"
      [attr.aria-label]="t('dataTable')"
      [resizableColumns]="true"
      [autoLayout]="true"
      [currentPageReportTemplate]="t('paginationMessage')"
      [sortMode]="sortMode"
      [styleClass]="rowSize"
      [dataKey]="dataKey"
      [columns]="visibleCols"
      [filterDelay]="filterDelay"
      [loading]="loading"
      [paginator]="paginator"
      [rowHover]="rowHover"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [scrollable]="scrollable"
      [showCurrentPageReport]="showCurrentPageReport"
      [value]="data"
      [lazyLoadOnInit]="true"
      [lazy]="true"
      (onLazyLoad)="sortable && onLazyLoadChanged($event)">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="table-header__buttons-container">
            <div class="table-header__buttons">
              @if (displayClearButton) {
                <button
                  pButton
                  [label]="t('clear') | titlecase"
                  [attr.aria-label]="t('clearFilters')"
                  class="p-button-outlined stack-order-high"
                  icon="pi pi-filter-slash"
                  (click)="clear(dt)"></button>
              }
              @if (displaySaveFilterButton) {
                <button
                  pButton
                  [label]="t('lockFilters') | titlecase"
                  [attr.aria-label]="t('lockFilters')"
                  (click)="onSaveFiltersClick()"
                  class="p-button-outlined stack-order-high"></button>
              }
            </div>
            <div class="table-header__buttons">
              @if (displayExportButton) {
                <button
                  pButton
                  [label]="t('export') | titlecase"
                  class="p-button-outlined text-border-color"
                  icon="pi pi-download"
                  (click)="exportExcel.emit()"
                  [attr.aria-label]="t('exportToExcel')"></button>
              }
              @if (displayColsReorderButton) {
                <button
                  pButton
                  cdkOverlayOrigin
                  #trigger="cdkOverlayOrigin"
                  class="p-button-outlined text-border-color"
                  icon="pi pi-table"
                  [label]="t('columns') | titlecase"
                  (click)="isColsOverlayOpen = !isColsOverlayOpen"
                  [attr.aria-label]="t('modifyColumnsSettings')"></button>
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOffsetY]="6"
                  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="isColsOverlayOpen"
                  [cdkConnectedOverlayHasBackdrop]="true"
                  [cdkConnectedOverlayBackdropClass]="
                    'cdk-overlay-transparent-backdrop'
                  "
                  (backdropClick)="isColsOverlayOpen = !isColsOverlayOpen">
                  <shared-grid-columns
                    [columns]="userSortedCols"
                    [resetButtonDisabled]="columnsResetButtonDisabled"
                    (colsReordered)="onColsReordered($event)"
                    (visibleCols)="onColsChange($event)"
                    (resetOrder)="onResetColsOrder()"></shared-grid-columns>
                </ng-template>
              }
            </div>
          </div>
          @if (displayFilterSummary) {
            @if (filteringConfig$ | async; as filteringConfig) {
              <div
                class="table-header__active-filters"
                role="list"
                [attr.aria-label]="t('activeFilters')">
                <shared-active-filter-tags
                  [filteringConfig]="filteringConfig"
                  [columns]="columns"
                  (removeFilter)="onRemoveFilterTags($event)">
                </shared-active-filter-tags>
              </div>
            }
          }
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr class="header-row">
          @if (selectable) {
            <th style="width: 4rem" class="select-tristate">
              <p-triStateCheckbox
                label=""
                [checkboxFalseIcon]="'pi pi-minus'"
                [(ngModel)]="selectAll"
                (onChange)="onChangeSelectAll($event)"></p-triStateCheckbox>
            </th>
          }
          @for (col of columns; track col.field) {
            @switch (col.type) {
              @case (ColumnType.TextSearchFilter) {
                <th
                  [frozen]="col.fixed"
                  [pSortableColumn]="col.field"
                  pFrozenColumn
                  pResizableColumn
                  class="table-header-dimension"
                  scope="col">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      [field]="col.field"
                      [showOperator]="false"
                      [showClearButton]="false"
                      [showApplyButton]="false"
                      [showMatchModes]="false"
                      [showAddButton]="false"
                      matchMode="startsWith"
                      display="menu">
                      <ng-template
                        pTemplate="filter"
                        let-value
                        let-filter="filterCallback">
                        <shared-input-filter
                          [field]="col.field"
                          [filteringConfig$]="filteringConfig$"
                          [filter]="filter"></shared-input-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.SearchCheckboxFilter) {
                <th
                  [frozen]="col.fixed"
                  [pSortableColumn]="col.field"
                  pFrozenColumn
                  pResizableColumn
                  class="table-header-dimension"
                  scope="col">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      [field]="col.field"
                      [showOperator]="false"
                      [showClearButton]="false"
                      [showApplyButton]="false"
                      [showMatchModes]="false"
                      [showAddButton]="false"
                      matchMode="in"
                      display="menu">
                      <ng-template
                        pTemplate="filter"
                        let-value
                        let-filter="filterCallback">
                        <shared-checkbox-filter
                          [field]="col.field"
                          [filteringConfig$]="filteringConfig$"
                          [filter]="filter"
                          [filterFlag]="true"
                          [options]="
                            filterOptions[col.field]
                          "></shared-checkbox-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.CheckboxFilter) {
                <th
                  [pSortableColumn]="col.field"
                  [frozen]="col.fixed"
                  pFrozenColumn
                  pResizableColumn
                  class="table-header-dimension"
                  scope="col">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      [field]="col.field"
                      [showOperator]="false"
                      [showClearButton]="false"
                      [showApplyButton]="false"
                      [showMatchModes]="false"
                      [showAddButton]="false"
                      matchMode="in"
                      display="menu">
                      <ng-template
                        pTemplate="filter"
                        let-value
                        let-filter="filterCallback">
                        <shared-checkbox-filter
                          [field]="col.field"
                          [filteringConfig$]="filteringConfig$"
                          [filter]="filter"
                          [filterFlag]="false"
                          [options]="filterOptions[col.field]">
                        </shared-checkbox-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.DateFilter) {
                <th
                  [pSortableColumn]="col.field"
                  [frozen]="col.fixed"
                  pFrozenColumn
                  pResizableColumn
                  class="table-header-dimension"
                  scope="col">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      [field]="col.field"
                      [showOperator]="false"
                      [showClearButton]="false"
                      [showApplyButton]="false"
                      [showMatchModes]="false"
                      [showAddButton]="false"
                      [matchMode]="getMatchMode(col.field)"
                      type="date"
                      display="menu">
                      <ng-template
                        pTemplate="filter"
                        let-value
                        let-filter="filterCallback">
                        <shared-date-filter
                          [field]="col.field"
                          [selectionMode]="'range'"
                          [filteringConfig$]="filteringConfig$"
                          [filter]="filter">
                        </shared-date-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.Actions) {
                <th
                  [frozen]="col.fixed"
                  pFrozenColumn
                  pResizableColumn
                  class="table-header-dimension-actions"
                  scope="col"></th>
              }
              @case (ColumnType.EventActions) {
                <th
                  [frozen]="col.fixed"
                  [pSortableColumn]="col.field"
                  pFrozenColumn
                  alignFrozen="right"
                  pResizableColumn
                  class="table-header-dimension-event-actions"
                  scope="col">
                  <div class="table-header__th">
                    <span>{{ t(col.displayName) | uppercase }}</span>
                  </div>
                </th>
              }
            }
          }
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-rowIndex="rowIndex">
        <tr class="p-selectable-row">
          @if (selectable) {
            <td pFrozenColumn>
              <p-checkbox
                binary="true"
                [(ngModel)]="selectedData[rowIndex]"
                (onChange)="updateSelectedRows()"></p-checkbox>
            </td>
          }
          @for (col of columns; track col.field) {
            @switch (col.cellType) {
              @case (CellType.Checkbox) {
                <td [frozen]="col.fixed" pFrozenColumn>
                  <p-tableCheckbox
                    [value]="rowData[col.field]"></p-tableCheckbox>
                </td>
              }
              @case (CellType.Link) {
                <td
                  [style.max-width.%]="100"
                  [frozen]="col.fixed"
                  pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  @if (route) {
                    <a
                      [routerLink]="[route, rowData[col.field]]"
                      [queryParams]="
                        createQueryParams(queryParamProperty, rowData)
                      "
                      ariaCurrentWhenActive="page"
                      attr.aria-label="Navigate to id {{ rowData[col.field] }}"
                      >{{ rowData[col.field] }}</a
                    >
                  } @else {
                    <a attr.aria-label="Id {{ rowData[col.field] }}">{{
                      rowData[col.field]
                    }}</a>
                  }
                </td>
              }
              @case (CellType.Tag) {
                <td
                  [style.max-width]="minStatusTdDefaultWidth"
                  [frozen]="col.fixed"
                  class="tag"
                  pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <shared-tag
                    [tagValue]="rowData[col.field]"
                    [tagClassesMap]="tagStatesMap"></shared-tag>
                </td>
              }
              @case (CellType.Status) {
                <td
                  [style.max-width]="minStatusTdDefaultWidth"
                  [frozen]="col.fixed"
                  pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <shared-status
                    [statusClassMap]="statusStatesMap"
                    [value]="rowData[col.field]"></shared-status>
                </td>
              }
              @case (CellType.Date) {
                <td
                  [style.max-width]="minTdDateWidth"
                  sharedGridTooltip
                  [tooltipPaddingSize]="rowSize"
                  [frozen]="col.fixed"
                  pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  {{ rowData[col.field] }}
                </td>
              }
              @case (CellType.Actions) {
                <td
                  [style.max-width]="minTdActionsWidth"
                  [frozen]="col.fixed"
                  pFrozenColumn
                  class="action-column">
                  <shared-action
                    [actions]="rowData.actions"
                    (triggerAction)="
                      triggerAction.emit({
                        event: $event,
                        fileName: rowData.fileName,
                        documentId: rowData.documentId,
                      })
                    "></shared-action>
                </td>
              }
              @case (CellType.EventActions) {
                <td
                  [style.max-width]="minTdActionsWidth"
                  [frozen]="col.fixed"
                  pFrozenColumn
                  alignFrozen="right"
                  class="action-column">
                  <shared-event-action
                    [displayConfirmButton]="
                      rowData.eventActions.displayConfirmButton
                    "
                    [eventActions]="rowData.eventActions.actions">
                  </shared-event-action>
                </td>
              }
              @case (CellType.TextWithIcon) {
                <td [frozen]="col.fixed" pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <shared-text-with-icon
                    [displayIcon]="rowData.primarySite.isPrimarySite"
                    [text]="rowData[col.field]"
                    [iconClass]="rowData.primarySite.iconClass"
                    [tooltipMessage]="
                      rowData.primarySite.tooltipMessage
                    "></shared-text-with-icon>
                </td>
              }
              @default {
                <td
                  [style.max-width]="minTdDefaultWidth"
                  sharedGridTooltip
                  [tooltipPaddingSize]="rowSize"
                  [frozen]="col.fixed"
                  pFrozenColumn>
                  <span class="p-column-title">{{ col.displayName }}</span>
                  {{ rowData[col.field] }}
                </td>
              }
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr class="grid-message">
          <td [attr.colspan]="colsNo">{{ t(noDataMessage) }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <span>{{ t('itemsPerPage') }}:</span>
        <p-dropdown
          ariaLabel="Change rows count"
          appendTo="body"
          [disabled]="isPaginationDropdownDisabled"
          [options]="rowsPerPageOptions"
          [(ngModel)]="rows"
          (onChange)="onSelectPaginationRows()"
          (ngModelChange)="onChangeRows($event)" />
      </ng-template>
    </p-table>
  </div>
</ng-container>

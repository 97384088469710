<div class="text-with-icon">
    <div class="text-with-icon__icon-container">
        @if (displayIcon && iconPosition === 'prefix') {
            <i class="text-with-icon__icon {{ iconClass }}"
                [pTooltip]="tooltipMessage"
                tooltipPosition="top">
            </i>
        }
    </div>
    
    <span class="text-with-icon__text">{{ text }}</span>

    <div class="text-with-icon__icon-container">
        @if (displayIcon && iconPosition === 'suffix') {
            <i class="text-with-icon__icon {{ iconClass }}"
                [pTooltip]="tooltipMessage"
                tooltipPosition="top">
            </i>
        }
    </div>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoDirective } from '@jsverse/transloco';

import {
  GridAction,
  GridActionEvent,
  GridActionType,
} from '../../../../models/grid-actions.model';

@Component({
  selector: 'shared-action',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './action.component.html',
  styleUrl: './action.component.scss',
})
export class ActionComponent {
  @Input() actions!: GridAction[];

  @Output() triggerAction = new EventEmitter<GridActionEvent>();

  GridActionType = GridActionType;

  onDownloadClick({ url, actionType }: GridAction): void {
    this.triggerAction.emit({ url, actionType });
  }

  onDeleteClick({ actionType }: GridAction): void {
    this.triggerAction.emit({ actionType });
  }
}

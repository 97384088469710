import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SelectItem } from 'primeng/api';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'shared-toggle-btn',
  standalone: true,
  imports: [CommonModule, SelectButtonModule, ReactiveFormsModule],
  templateUrl: './toggle-btn.component.html',
  styleUrl: './toggle-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleBtnComponent implements OnInit {
  formGroup!: FormGroup;

  @Input() items: SelectItem[] = [];

  ngOnInit() {
    this.formGroup = new FormGroup({
      value: new FormControl('off'),
    });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  WritableSignal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActiveFilterTagComponent } from './active-filter-tag/active-filter-tag.component';
import { TagComponent } from '../../grid-cell-renderers/tag';
import {
  FilterToRemove,
  FilteringConfig,
  ColumnDefinition,
  ActiveFilterTag,
} from '../../../../models';

@Component({
  selector: 'shared-active-filter-tags',
  standalone: true,
  imports: [CommonModule, TagComponent, ActiveFilterTagComponent],
  templateUrl: './active-filter-tags.component.html',
  styleUrl: './active-filter-tags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveFilterTagsComponent {
  private _filteringConfig!: FilteringConfig;

  @Output() removeFilter = new EventEmitter<FilterToRemove>();
  @Input() columns!: ColumnDefinition[];

  @Input() set filteringConfig(value: FilteringConfig) {
    this._filteringConfig = value;
    this.updatesAfterFilteringConfigChanges();
  }

  get filteringConfig(): FilteringConfig {
    return this._filteringConfig;
  }

  public activeFilters: WritableSignal<ActiveFilterTag[]> = signal([]);

  public onRemoveFilter(filter: ActiveFilterTag): void {
    this.removeFilter.emit({
      fieldName: filter.key,
      filterValue: filter.value,
    });
  }

  private updatesAfterFilteringConfigChanges(): void {
    this.activeFilters.set(
      this.mapFiltersToActiveFilterTag(this._filteringConfig),
    );
  }

  private mapFiltersToActiveFilterTag(
    filtersObj: FilteringConfig,
  ): ActiveFilterTag[] {
    return Object.entries(filtersObj).reduce((result, [key, filters]) => {
      const mappedFilters =
        filters.value?.map((f) => ({
          ...f,
          type: this.getFilterTypeByKey(key),
          key,
        })) || [];

      return result.concat(mappedFilters);
    }, [] as ActiveFilterTag[]);
  }

  private getFilterTypeByKey(key: string): string {
    return this.columns.find((c) => c.field === key)?.displayName || key;
  }
}

import { GridConfig, GridLazyLoadEvent } from '../models';
import { createSortingConfig } from './grid-sorting.helpers';
import { createPaginationConfig } from './grid-pagination.helpers';
import { createFilteringConfig } from './grid-filtering.helpers';
import { GridConfigProcessor } from './grid-config.processor';

export const createGridConfig = (event: GridLazyLoadEvent): GridConfig => ({
  sorting: createSortingConfig(event),
  pagination: createPaginationConfig(event),
  filtering: createFilteringConfig(event),
});

export const applyGridConfigOnData = <T extends Record<string, any>>(
  data: T[],
  config: GridConfig,
): T[] =>
  new GridConfigProcessor(data, config)
    .filterData()
    .sortData()
    .applyPagination();

export const getNumberOfFilteredRecords = <T extends Record<string, any>>(
  data: T[],
  config: GridConfig,
): number =>
  new GridConfigProcessor(data, config).filterData().sortData().getData()
    .length;

<ng-container *transloco="let t">
  <p-listbox
    (onChange)="filter($event.value); onChangeOption($event)"
    [options]="options"
    [optionLabel]="optionLabel"
    [filter]="filterFlag"
    [multiple]="multiple"
    [checkbox]="true"
    [showToggleAll]="false"
    [style]="{ width: width + 'px' }"
    [styleClass]="filterFlag ? 'filter-header' : ''"
    [listStyle]="{ 'max-height': maxHeight + 'px' }"
    [(ngModel)]="selectedItems">
    <ng-template pTemplate="header">
      <p-triStateCheckbox
        (onChange)="
          filter($event.value ? options : []); onChangeSelectAll($event)
        "
        [label]="t('All')"
        [checkboxFalseIcon]="'pi pi-minus'"
        [(ngModel)]="selectAll"></p-triStateCheckbox>
    </ng-template>
  </p-listbox>
</ng-container>

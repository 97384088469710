import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DropdownModule } from 'primeng/dropdown';

import { FormsModule } from '@angular/forms';

import { LanguageOption } from '../../models';

@Component({
  selector: 'shared-language-switcher',
  standalone: true,
  imports: [CommonModule, RouterModule, DropdownModule, FormsModule],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
  @Input()
  languages: LanguageOption[] = [];
  selectedLanguage = {
    language: 'English',
    isSelected: true,
  };

  @Output() selectedLanguageChanged = new EventEmitter<string>();

  selectLanguage(language: string): void {
    this.selectedLanguageChanged.emit(language);
  }
}

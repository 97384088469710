import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
// For dynamic progressbar demo
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FilterMetadata } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import {
  TriStateCheckboxChangeEvent,
  TriStateCheckboxModule,
} from 'primeng/tristatecheckbox';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslocoDirective } from '@jsverse/transloco';

import { createGridConfig } from '../../../helpers';
import {
  CellType,
  ColumnDefinition,
  ColumnType,
  FilterMode,
  FilterToRemove,
  FilteringConfig,
  GridActionEvent,
  GridConfig,
  GridLazyLoadEvent,
} from '../../../models';
import {
  ActionComponent,
  TextWithIconComponent,
  StatusComponent,
  TagComponent,
  EventActionComponent,
} from '../grid-cell-renderers';
import { GridTooltipDirective } from '../../../directives';
import {
  ActiveFilterTagsComponent,
  CheckboxFilterComponent,
  DateFilterComponent,
  InputFilterComponent,
} from '../grid-filters';
import { GridColumnsComponent } from '../grid-columns';

type FilterType = {
  [key: string]: FilterMetadata | FilterMetadata[];
};

type PreferenceDataType = {
  filters?: {
    [key: string]: FilterMetadata | FilterMetadata[];
  };
  rowsPerPage?: number;
};

@Component({
  selector: 'shared-grid',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    TagModule,
    SliderModule,
    ProgressBarModule,
    ToastModule,
    ButtonModule,
    CheckboxModule,
    TriStateCheckboxModule,
    TranslocoDirective,
    TagComponent,
    GridTooltipDirective,
    CheckboxFilterComponent,
    InputFilterComponent,
    DateFilterComponent,
    OverlayPanelModule,
    OverlayModule,
    GridColumnsComponent,
    ActiveFilterTagsComponent,
    PaginatorModule,
    StatusComponent,
    AsyncPipe,
    RouterModule,
    ActionComponent,
    EventActionComponent,
    TextWithIconComponent,
  ],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
  private _data: any[] = [];
  private defaultRowsPerPage = 10;

  private actualRows = this.defaultRowsPerPage;
  private actualFilters: FilterType = {};
  private preferenceToSave: PreferenceDataType = {};

  @ViewChild('dt') dataTable!: Table;

  @Input() columns!: ColumnDefinition[];

  @Input() set data(data: any) {
    this._data = data;

    if (this.selectable) {
      this.selectedData = new Array(this.data.length).fill(false);
      this.selectAll = null;
    }
  }

  get data() {
    return this._data;
  }

  @Input() set preferenceData(preferenceData: PreferenceDataType) {
    this.preferenceToSave = JSON.parse(JSON.stringify(preferenceData));

    if (!preferenceData) {
      return;
    }

    if (this.dataTable) {
      this.applyFilters(preferenceData.filters as FilterType);
      this.dataTable._filter();

      this.applyPaginatorSize(preferenceData.rowsPerPage);
    }
  }

  @Input() totalRecords!: number;
  @Input() dateFormat = 'dd-MM-yyyy';
  @Input() dataKey = 'id';
  @Input() filterOptions!: any;
  @Input() filterDelay = 0;
  @Input() displaySaveFilterButton = true;
  @Input() displayClearButton = true;
  @Input() displayExportButton = true;
  @Input() displayColsReorderButton = false;
  @Input() displayFilterSummary = true;
  @Input() shouldSavePaginationSelectorSize = false;
  @Input() loading = false;
  @Input() noDataMessage!: string;
  @Input() paginator = true;
  @Input() rowHover = false;
  @Input() rows = this.defaultRowsPerPage;
  @Input() rowSize = 'p-datatable-lg';
  @Input() rowsPerPageOptions = [10, 25, 50];
  @Input() scrollable = true;
  @Input() selectable = false;
  @Input() showCurrentPageReport = true;
  @Input() sortable = false;
  @Input() sortMode: 'single' | 'multiple' = 'single';
  @Input() filteringConfig$!: Observable<FilteringConfig>;
  @Input() tagStatesMap!: { [key: string]: string };
  @Input() statusStatesMap!: { [key: string]: string };
  @Input() route!: string;
  @Input() queryParamProperty!: string;

  @Output() gridConfigChangedEvent = new EventEmitter<GridConfig>();
  @Output() removeFilter = new EventEmitter<{
    fieldName: string;
    filterValue: string;
  }>();
  @Output() exportExcel = new EventEmitter<void>();
  @Output() triggerAction = new EventEmitter<{
    event: GridActionEvent;
    fileName: string;
    documentId: number;
  }>();
  @Output() savePreference = new EventEmitter<PreferenceDataType>();

  ColumnType = ColumnType;
  CellType = CellType;

  visibleCols!: any[];
  userSortedCols!: any[];
  selectedData: any[] = [];
  selectAll: boolean | null = null;

  minTdDefaultWidth?: string;
  minStatusTdDefaultWidth?: string;
  minTdDateWidth?: string;
  minTdActionsWidth?: string;

  colsNo!: string;

  activityValues: number[] = [0, 100];

  isColsOverlayOpen = false;

  columnsResetButtonDisabled = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const window = event.target as Window;
    this.resizeTableCell(window.innerWidth);
  }

  ngOnInit(): void {
    this.resizeTableCell(window.innerWidth);
    this.userSortedCols = JSON.parse(JSON.stringify(this.columns));
    this.refreshVisibleColumns();

    this.colsNo = String(this.columns.length);
  }

  onChangeRows(nOfRows: number): void {
    this.dataTable._rows = nOfRows;
    this.dataTable._filter();
  }

  onSelectPaginationRows() {
    this.preferenceToSave.rowsPerPage = this.actualRows;
    this.savePreference.emit(this.preferenceToSave);
  }

  clear(table: Table): void {
    table.clear();
  }

  onLazyLoadChanged(event: TableLazyLoadEvent): void {
    const ev: GridLazyLoadEvent = {
      ...event,
      paginationEnabled: this.paginator,
    };
    const gridConfig = createGridConfig(ev);
    this.gridConfigChangedEvent.emit(gridConfig);

    this.actualFilters = event.filters as FilterType;
    this.actualRows = event.rows as number;
  }

  onSaveFiltersClick() {
    this.preferenceToSave.filters = this.actualFilters;
    this.savePreference.emit(this.preferenceToSave);
  }

  onColsChange(event: string[]): void {
    const previousRemovedCols = this.userSortedCols
      .filter((column: ColumnDefinition) => column.disabled)
      .map((column: ColumnDefinition) => column.field);

    this.userSortedCols.forEach((column: ColumnDefinition, index: number) => {
      this.userSortedCols[index].disabled = !event.includes(column.field);
    });

    this.columnsResetButtonDisabled = false;
    this.refreshVisibleColumns();

    const removedCols: string[] = this.userSortedCols
      .filter((column: ColumnDefinition) => column.disabled)
      .map((column: ColumnDefinition) => column.field);

    const removedCol = removedCols.filter(
      (item) => previousRemovedCols.indexOf(item) === -1,
    );

    if (removedCol.length) {
      this.dataTable.filter(null, removedCol[0], FilterMode.In);

      if (
        this.dataTable.multiSortMeta?.length &&
        this.dataTable.multiSortMeta[0].field === removedCol[0]
      ) {
        this.dataTable._sortField = null;
        this.dataTable._sortOrder = this.dataTable.defaultSortOrder;
        this.dataTable._multiSortMeta = null;
        this.dataTable.tableService.onSort(null);
      }
    }
  }

  onColsReordered(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.userSortedCols,
      event.previousIndex,
      event.currentIndex,
    );

    this.columnsResetButtonDisabled = false;
    this.refreshVisibleColumns();
  }

  onResetColsOrder(): void {
    this.userSortedCols = JSON.parse(JSON.stringify(this.columns));
    this.columnsResetButtonDisabled = true;
    this.refreshVisibleColumns();
  }

  getMatchMode(field: string): string {
    return field.toLocaleLowerCase().includes('start')
      ? FilterMode.DateAfter
      : FilterMode.DateBefore;
  }

  onRemoveFilterTags(filterToRemove: FilterToRemove): void {
    const tableFilters = JSON.parse(
      JSON.stringify(this.dataTable.filters[filterToRemove.fieldName]),
    );
    const filters = Array.isArray(tableFilters) ? tableFilters : [tableFilters];

    filters.forEach((filter: FilterMetadata) => {
      const index = filter.value.findIndex(
        (value: any) => value.value === filterToRemove.filterValue,
      );

      if (index !== -1) {
        filter.value.splice(index, 1);
      }
    });

    this.dataTable.filters[filterToRemove.fieldName] = filters;
    this.dataTable._filter();
  }

  createQueryParams(property: string, rowData: any): any {
    return { [property]: rowData[property] };
  }

  onChangeSelectAll(event: TriStateCheckboxChangeEvent): void {
    if (event.value) {
      this.selectedData = this.data.map(() => true);
    } else {
      this.selectedData = this.data.map(() => false);
    }

    this.updateSelectedRows();
  }

  updateSelectedRows() {
    const allSelected = this.selectedData.every((val) => val === true);
    const someSelected = this.selectedData.some((val) => val === true);

    if (allSelected) {
      this.selectAll = true;
    } else if (someSelected) {
      this.selectAll = false;
    } else {
      this.selectAll = null;
    }
  }

  private applyFilters(filters: FilterType | null): void {
    if (filters && Object.keys(filters).length > 0) {
      this.dataTable.filters = filters;
      this.dataTable._filter();
    }
  }

  private applyPaginatorSize(rowsPerPage?: number): void {
    if (this.shouldSavePaginationSelectorSize) {
      this.rows = rowsPerPage || this.defaultRowsPerPage;
      this.onChangeRows(this.rows);
    }
  }

  private resizeTableCell(width: number): void {
    switch (true) {
      case width > 2000:
        this.minTdDefaultWidth = '14rem';
        this.minStatusTdDefaultWidth = '18rem';
        this.minTdDateWidth = '18rem';
        this.minTdActionsWidth = '6rem';
        break;
      case width > 1700:
        this.minTdDefaultWidth = '10rem';
        this.minStatusTdDefaultWidth = '14rem';
        this.minTdDateWidth = '14rem';
        this.minTdActionsWidth = '6rem';
        break;
      default:
        this.minTdDefaultWidth = '9rem';
        this.minStatusTdDefaultWidth = '14rem';
        this.minTdDateWidth = '14rem';
        this.minTdActionsWidth = '5rem';
        break;
    }
  }

  private refreshVisibleColumns(): void {
    this.visibleCols = this.userSortedCols.filter(
      (column: ColumnDefinition) => !column.disabled,
    );
  }

  get isPaginationDropdownDisabled(): boolean {
    if (!this.totalRecords || this.rowsPerPageOptions.length === 0) {
      return true;
    }

    return this.totalRecords <= this.rowsPerPageOptions[0];
  }
}

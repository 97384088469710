import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import {
  TriStateCheckboxChangeEvent,
  TriStateCheckboxModule,
} from 'primeng/tristatecheckbox';

import { ColumnDefinition } from '../../../models';

@Component({
  selector: 'shared-grid-columns',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DragDropModule,
    FormsModule,
    TranslocoDirective,
    TriStateCheckboxModule,
  ],
  templateUrl: './grid-columns.component.html',
  styleUrl: './grid-columns.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColumnsComponent {
  private _columns!: ColumnDefinition[];

  @Input() resetButtonDisabled!: boolean;

  @Input() set columns(value: ColumnDefinition[]) {
    this._columns = value;
    this.updatesAfterColumnsChanges();
  }

  get columns(): ColumnDefinition[] {
    return this._columns;
  }

  @Output() visibleCols = new EventEmitter<string[]>();
  @Output() colsReordered = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() resetOrder = new EventEmitter<void>();

  selectedColumns: string[] = [];
  selectAll: boolean | null = null;
  dropListDisabled = false;

  updatesAfterColumnsChanges(): void {
    this.selectedColumns = this.columns
      .filter((column: ColumnDefinition) => !column.disabled)
      .map((column: ColumnDefinition) => column.field);

    this.updateSelectAllCheckbox();
  }

  columnsChanged(): void {
    this.updateSelectAllCheckbox();

    this.visibleCols.emit(this.selectedColumns);
  }

  onChangeSelectAll(event: TriStateCheckboxChangeEvent): void {
    if (event.value) {
      this.selectedColumns = this.columns.map(
        (column: ColumnDefinition) => column.field,
      );
      this.visibleCols.emit(this.selectedColumns);
    } else {
      this.selectedColumns = this.columns
        .filter((column: ColumnDefinition) => column.fixed)
        .map((column: ColumnDefinition) => column.field);

      this.visibleCols.emit(this.selectedColumns);
      this.selectAll = null;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (this.columns[event.currentIndex].fixed) {
      this.dropListDisabled = true;
      this.dropListDisabled = false;

      return;
    }

    this.colsReordered.emit(event);
  }

  reset(): void {
    this.resetOrder.emit();
  }

  private updateSelectAllCheckbox(): void {
    if (this.selectedColumns.length === this.columns.length) {
      this.selectAll = true;
    } else if (this.selectedColumns.length === 0) {
      this.selectAll = null;
    } else {
      this.selectAll = false;
    }
  }
}

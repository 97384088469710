<ng-container *transloco="let t">
  <p-tag [rounded]="true" styleClass="active-filter-tag">
    <div class="active-filter-tag__container">
      <span
        class="active-filter-tag__label"
        sharedGridTooltip
        role=""
        attr.aria-label="{{ t(tag.type) + ': ' + tag.label }}">
        {{ t(tag.type) + ': ' + tag.label }}</span>
      <i
        class="pi pi-times-circle active-filter-tag__icon"
        (click)="removeFilter.emit(tag)"
        (keydown.enter)="removeFilter.emit(tag)"
        (keydown.space)="removeFilter.emit(tag)"
        role="button"
        tabindex="0"
        attr.aria-label="Remove filter {{ t(tag.type) + ': ' + tag.label }}"></i>
    </div>
  </p-tag>
</ng-container>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'shared-custom-split-btn',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './custom-split-btn.component.html',
  styleUrl: './custom-split-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSplitBtnComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() leftIcon = '';
  @Input() disabled = false;

  @Output() clickBtn = new EventEmitter<void>();
}

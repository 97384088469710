import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';

import { CustomCalendarOptions } from '../../models';

@Component({
  selector: 'shared-custom-full-calendar',
  standalone: true,
  imports: [CommonModule, FullCalendarModule],
  templateUrl: './custom-full-calendar.component.html',
  styleUrl: './custom-full-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFullCalendarComponent implements OnInit {
  @Input() customCalendarOptions!: CustomCalendarOptions;

  calendarOptions: WritableSignal<CalendarOptions | undefined> =
    signal(undefined);

  data = [
    {
      startDate: '2024-08-08',
      endDate: '2024-08-09',
      status: 'Confirmed',
      service: 'Event 1Event 1Event 1Event 1Event 1',
      site: '',
      city: '',
      auditType: '',
      leadAuditor: '',
      siteRepresentative: '',
    },
    {
      startDate: '2024-08-08',
      endDate: '2024-08-10',
      status: 'To Be Confirmed',
      service: 'Event 2',
      site: '',
      city: '',
      auditType: '',
      leadAuditor: '',
      siteRepresentative: '',
    },
    {
      startDate: '2024-08-08',
      endDate: '2024-08-10',
      status: 'Cancelled',
      service: 'Event 3',
      site: '',
      city: '',
      auditType: '',
      leadAuditor: '',
      siteRepresentative: '',
    },
    {
      startDate: '2024-08-08',
      endDate: '2024-08-10',
      status: 'To Be Confirmed By DNV',
      service: 'Event 4',
      site: '',
      city: '',
      auditType: '',
      leadAuditor: '',
      siteRepresentative: '',
    },
    {
      startDate: '2024-08-08',
      endDate: '2024-08-10',
      status: 'Confirmed',
      service: 'Event 5 Event 5 Event 5 Event 5',
      site: '',
      city: '',
      auditType: '',
      leadAuditor: '',
      siteRepresentative: '',
    },
  ];

  ngOnInit(): void {
    this.calendarOptions.set({
      ...this.customCalendarOptions,
      plugins: [dayGridPlugin, multiMonthPlugin],
      headerToolbar: {
        start: 'prev,next today',
        center: 'title',
        end: 'dayGridMonth,multiMonthYear',
      },
      multiMonthMaxColumns: 2,
      events: this.data.map((event) => ({
        title: event.service,
        start: event.startDate,
        end: event.endDate,
        className: this.customCalendarOptions.scheduleStatusMap[event.status],
      })),
      height: 'auto',
      dayMaxEvents: true,
      views: {
        dayGrid: {
          dayMaxEvents: 3,
        },
      },
    });
  }
}

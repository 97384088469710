<div class="custom-split-btn" (click)="!disabled ? clickBtn.emit() : null">
  <p-button
    [label]="label"
    [icon]="leftIcon"
    [disabled]="disabled"
    class="custom-split-btn__btn" />
  <p-button
    [icon]="icon"
    [disabled]="disabled"
    class="custom-split-btn__icon-btn"></p-button>
</div>
